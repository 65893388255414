import React, { useState, useEffect } from "react";
import { Container, Col, Row, Image } from "react-bootstrap";
import SEO from "../../components/shared/seo";
import { FadeUpBox, FadeIn } from "../../components/shared/transitions";
import {
  OverlayFill,
  OverlayFillGradient,
} from "../../components/shared/overlay";
import { useStaticQuery, graphql } from "gatsby";
import { CaseStudyNav } from "../../components/shared/case-study-nav";
import { Controller, Scene } from "react-scrollmagic";
import LayoutCaseStudy from "../../components/layouts/layout-case-study";
import { ParallaxBanner } from "react-scroll-parallax";
import { QuoteBlock } from "../../components/shared/quote-block";
import { Helmet } from "react-helmet";
import Swiper from "react-id-swiper";
import technicalIcon from "../../images/technical-icon.svg";
import uxIcon from "../../images/ux-icon.svg";
import kenticoLogo from "../../images/kentico-logo.png";
import wordpressLogo from "../../images/wordpress-logo.png";
import solution01Icon from "../../images/solution01-icon.png";
import solution02Icon from "../../images/solution02-icon.png";
import solution03Icon from "../../images/solution03-icon.png";
import firstClickIcon from "../../images/first-click-icon.svg";
import onTimeIcon from "../../images/on-time-icon.svg";
import timeOnTaskIcon from "../../images/time-on-task-icon.svg";
import retentionIcon from "../../images/retention-icon.svg";
import engagementIcon from "../../images/engagement-icon.svg";
import heatMapIcon from "../../images/heat-map-icon.svg";
import adoptionIcon from "../../images/adoption-icon.svg";
import clickEventIcon from "../../images/click-events-icon.svg";
import successIcon from "../../images/success-icon.svg";
import homePage from "../../video/home-page.mp4";
import navigation from "../../video/navigation.mp4";
import login from "../../video/login.mp4";
import search from "../../video/search.mp4";
import development from "../../video/developments.mp4";
import videoPosterPreviousHomepage from "../../images/previous-homepage-poster.png";
import videoPosterLogin from "../../images/login-poster.png";
import videoPosterNavigation from "../../images/previous-navigation-poster.png";
import videoPosterSearch from "../../images/search-poster.png";
import videoPosterDevelopments from "../../images/developments-poster.png";




function tabOne() {
  var element = document.getElementById("accountability-tabs");
  element.classList.remove("tab-two-active");
  element.classList.add("tab-one-active");

}

function tabTwo() {
  var element = document.getElementById("accountability-tabs");
  element.classList.remove("tab-one-active");
  element.classList.add("tab-two-active");

}



function videoOne() {
  var element = document.getElementById("video-tabs");
  element.classList.remove("tab-one-active", "tab-two-active", "tab-three-active", "tab-four-active", "tab-five-active");
  element.classList.add("tab-one-active");

}


function videoTwo() {
  var element = document.getElementById("video-tabs");
  element.classList.remove("tab-one-active", "tab-two-active", "tab-three-active", "tab-four-active", "tab-five-active");
  element.classList.add("tab-two-active");

}


function videoThree() {
  var element = document.getElementById("video-tabs");
  element.classList.remove("tab-one-active", "tab-two-active", "tab-three-active", "tab-four-active", "tab-five-active");
  element.classList.add("tab-three-active");

}


function videoFour() {
  var element = document.getElementById("video-tabs");
  element.classList.remove("tab-one-active", "tab-two-active", "tab-three-active", "tab-four-active", "tab-five-active");
  element.classList.add("tab-four-active");

}

function videoFive() {
  var element = document.getElementById("video-tabs");
  element.classList.remove("tab-one-active", "tab-two-active", "tab-three-active", "tab-four-active", "tab-five-active");
  element.classList.add("tab-five-active");

}


function technicalScope() {
  var element = document.getElementById("technicalProcess");
  element.classList.remove("technical-slider-active", "uxprocess-slider-active");
  element.classList.add("technical-slider-active");

}



function uxProcess() {
  var element = document.getElementById("technicalProcess");
  element.classList.remove("technical-slider-active", "uxprocess-slider-active");
  element.classList.add("uxprocess-slider-active");

}


function technicalChallenge() {
  var element = document.getElementById("challenge");
  element.classList.remove("technical-challenge-slider-active", "ux-challenge-slider-active");
  element.classList.add("technical-challenge-slider-active");

}


function uxChallenge() {
  var element = document.getElementById("challenge");
  element.classList.remove("technical-challenge-slider-active", "ux-challenge-slider-active");
  element.classList.add("ux-challenge-slider-active");

}






const params1 = {
  slidesPerView: "auto",
  spaceBetween: 15,
  freeMode: true,
  pagination: {
    el: ".swiper-pagination",
    clickable: true,
  },
};

const params2 = {
  slidesPerView: "auto",
  spaceBetween: 15,
  freeMode: true,
  pagination: {
    el: ".swiper-pagination",
    clickable: true,
  },
};

const params3 = {
  slidesPerView: "auto",
  spaceBetween: 15,
  freeMode: true,
  pagination: {
    el: ".swiper-pagination",
    clickable: true,
  },
};

const params4 = {
  slidesPerView: "auto",
  spaceBetween: 15,
  freeMode: true,
  pagination: {
    el: ".swiper-pagination",
    clickable: true,
  },
};

function PindanPage({ location }) {
  // const { state = {} } = location;
  const [animate, setAnimate] = useState(false);
  const eaImages = useStaticQuery(graphql`
    query {
      nextWorkImage: file(relativePath: { eq: "stjohn-page-banner.png" }) {
        childImageSharp {
          fluid(maxWidth: 1000, quality: 100) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
      eaLogoSm: file(relativePath: { eq: "pindan-logo-sm.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 2000, quality: 100) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
    }
  `);

  useEffect(() => {
    const timer = setTimeout(() => {
      setAnimate(true);
    }, 500);

    return () => {
      clearTimeout(timer);
    };
  });

  return (
    <>
      <Helmet>
        <script type="application/ld+json">
          {`
                    {
            "@context": "http://schema.org",
            "@type": "Organization",
            "name": "Diviv Group",
            "description": ".NET applications, Progress Sitefinity CMS, Drupal, GovCMS, Sitecore Experience Platform, Microsoft Sharepoint, Dynamics CRM and Azure Hosting support in Perth Australia. Coupled with our creative and user experience team we are able to provide expert consultation and resources on a wide range of digital development projects across many industries.",
            "url": "https://www.diviv.com.au",
            "image": "https://www.diviv.com.au/icons/icon-96x96.png",
            "address": {
              "@type": "PostalAddress",
              "streetAddress": "165 / 15 Aberdeen St",
              "addressLocality": "Perth",
              "addressRegion": "WA",
              "postalCode": "6000",
              "addressCountry": "AU"
            },
            "aggregateRating": {
              "@type": "AggregateRating",
              "ratingValue": "5",
              "ratingCount": "1"
            },
            "review": {
              "@type": "Review",
              "author": "Michael Johnson",
              "datePublished": "2020-03-09",
              "reviewBody": "Diviv is a solution based partner that delivers front and back end website solutions with innovation, professionalism and integrity. They are a great team and a pleasure to work with.",
              "reviewRating": {
                "@type": "Rating",
                "bestRating": "5",
                "ratingValue": "5",
                "worstRating": "1"
              }
            }
          }
          `}
        </script>
      </Helmet>
      <SEO
        title="Pindan website migration"
        keywords="Diviv our work case studies"
        description="Diviv has extensive experience resolving complex digital problems for businesses in a wide variety of industries. Refer to our case studies to understand how we have delivered successful business outcomes for businesses like yours."
      />
      <LayoutCaseStudy>
        <div
          id="pindan-content"
          className="page-layout"
          data-menu-className="inverted"
        >
          <Container>
            <Row>
              <Col sm={12} md={12} lg={8}>
                <div className="client-logo--lg mb-4">
                  <Image src={eaImages.eaLogoSm.childImageSharp.fluid.src} />
                </div>
                <FadeUpBox pose={animate ? "enter" : "exit"}>
                  <FadeIn>
                    <h6>Website CMS migration</h6>
                  </FadeIn>
                  <FadeIn duration={200}>
                    <h2>Pindan website migration</h2>
                  </FadeIn>
                  <FadeIn duration={200}>
                    <p>
                      <strong>
                        PINDAN IS A LEADER IN THE AUSTRALIAN PROPERTY AND
                        CONSTRUCTION INDUSTRY
                      </strong>
                    </p>
                    <p>
                      The Diviv Group was entrusted to simplify and streamline the management of Pindan’s numerous campaign websites by migrating their sites from Kentico CMS to WordPress Open-Source Multisite CMS. Through this migration, Pindan can now successfully administer all sites from one centralised dashboard: minimising digital clutter and focusing the efforts of administrators’ company-wide.
                    </p>

                    <p>
                      During the migration process, Pindan completed a corporate brand refresh providing an opportunity to be integrated throughout the development process. With small UX enhancements, a fresher, cleaner look, Pindan’s content became more engaging, easier to find, and call to action tasks became simpler and more efficient to complete.

                    </p>
                  </FadeIn>
                </FadeUpBox>
              </Col>

              <Col sm={12} md={12} lg={4}>
                <FadeUpBox pose={animate ? "enter" : "exit"}>
                  <FadeIn>
                    <div className="client-meta">
                      Discover what else Pindan is up to by visiting their
                      website:
                      <div className="client-meta__link">
                        <a href="https://www.pindan.com.au/" target="_blank">
                          Pindan Builders
                        </a>
                      </div>
                    </div>
                  </FadeIn>
                </FadeUpBox>
              </Col>
            </Row>
          </Container>

          <Controller>
            <Scene
              triggerElement="carousel01"
              reverse={false}
              duration={2000}
              offset={200}
            >
              {(progress, event) => (
                <section id="carousel01">
                  <Container>
                    <Row id="challenge" className="carousel-section technical-challenge-slider-active">
                      <Col lg={6} sm={12} className="col-left" >
                        <FadeUpBox pose={progress > 0 ? "enter" : "exit"}>
                          <FadeIn>
                            <h2>DEFINE EACH CHALLENGE</h2>
                            <p>
                              Once an in-depth overview of Pindan’s objectives became clear, we identified the areas which would have the greatest cost-benefit to Pindan’s needs. Diviv Group comprehensively defined each challenge and problem in a user-centric manner which would act as a northern star as we navigated Pindan’s digital transformation.

                            </p>



                            <div className="button-wrapper">
                              <button className="btn technical" onClick={technicalChallenge}>Technical Challenge</button>
                              <button className="btn ux" onClick={uxChallenge}>UX Challenge</button>
                            </div>
                          </FadeIn>
                        </FadeUpBox>
                      </Col>

                      <Col lg={6} sm={12} className="col-right">
                        <FadeUpBox pose={progress > 0 ? "enter" : "exit"}>
                          <FadeIn>
                            <div className="carousel-slider-container">
                              <div className="carousel__outer swiper1">
                                <Swiper {...params1}>
                                  <div className="swiper-slide">
                                    <div className="slide__icon">
                                      <img
                                        src={technicalIcon}
                                        alt="Technical icon"
                                      />
                                    </div>
                                    <div className="slide__number">01</div>
                                    <div className="slide__title">
                                      Technical challenge
                                    </div>

                                    <div className="slide__sub-title">
                                      Administration / cost
                                    </div>

                                    <p>

                                      Using WordPress, a trusted, industry-leading open-source CMS, the business will greatly reduce ongoing licensing fees. WordPress offered an intuitive easy to use and already familiar interface and gave greater content entry flexibility and control over the previously ridged CMS implementation. As a result, content editor productivity has been increased providing greater user control, driving opportunity efficiency.
                                    </p>
                                  </div>

                                  <div className="swiper-slide">
                                    <div className="slide__icon">
                                      <img
                                        src={technicalIcon}
                                        alt="Technical icon"
                                      />
                                    </div>
                                    <div className="slide__number">02</div>
                                    <div className="slide__title">
                                      Technical challenge
                                    </div>

                                    <div className="slide__sub-title">
                                      Multisite CMS
                                    </div>
                                    <p>Using WordPress Multisite enables website administrators to save time and optimise administration workflows facilitated by a central dashboard to manage multiple websites. Pindan web Administrators will be able to manage their corporate website and several campaign websites from a single, easy to use interface.</p>
                                  </div>
                                </Swiper>
                                <div className="swiper-pagination swiper-pagination1"></div>
                              </div>

                              <div className="carousel__outer swiper2">
                                <Swiper {...params2}>
                                  <div className="swiper-slide">
                                    <div className="slide__icon">
                                      <img src={uxIcon} alt="UX icon" />
                                    </div>
                                    <div className="slide__number">01</div>
                                    <div className="slide__title">
                                      UX challenge
                                    </div>

                                    <div className="slide__sub-title">
                                      Navigation
                                    </div>

                                    <p>Having a unified, structured navigation system for all users. This will make finding information, completing tasks, and accessing key areas of the website much quicker and easier. We will know we are successful when analysing the quantitative and qualitative data, - feedback from user surveys.</p>
                                  </div>

                                  <div className="swiper-slide">
                                    <div className="slide__icon">
                                      <img src={uxIcon} alt="UX icon" />
                                    </div>
                                    <div className="slide__number">02</div>
                                    <div className="slide__title">
                                      UX challenge
                                    </div>

                                    <div className="slide__sub-title">Search</div>
                                    <p>
                                      Building a new search widget into every page will enable all users to access site information easily and efficiently. By keeping search and search results together, and including key search terms to prompt users to access strategic business areas of the website, it will help meet business objectives and increase funnel conversions.

                                    </p>
                                  </div>

                                  <div className="swiper-slide">
                                    <div className="slide__icon">
                                      <img src={uxIcon} alt="UX icon" />
                                    </div>
                                    <div className="slide__number">03</div>
                                    <div className="slide__title">
                                      UX challenge
                                    </div>

                                    <div className="slide__sub-title">
                                      Login portals
                                    </div>
                                    <p>
                                      By adding a login portal button in the fixed header that activates a slide-in panel will make it easy for staff, subcontractors and investors to login to restricted areas of the website no matter where they've scrolled to on any page. We will know if we are successful after evaluating quantitative and qualitative data and feedback from customer surveys. By comparing the previous website’s login footer location, we will know what the preferred location is.
                                    </p>
                                  </div>
                                  <div className="swiper-slide">
                                    <div className="slide__icon">
                                      <img src={uxIcon} alt="UX icon" />
                                    </div>
                                    <div className="slide__number">04</div>
                                    <div className="slide__title">
                                      UX challenge
                                    </div>

                                    <div className="slide__sub-title">
                                      Core services
                                    </div>
                                    <p>
                                      By emphasising Pindan’s value proposition, level of competence, culture and approach, as well as key differentiators found within Pindan’s capability statement, we will build trust in every website visitor, and work towards exceptional partnerships. We will know if we are successful through increased engagement, adoption, retention and lead generation while Pindan will also see the impact to their bottom line.

                                    </p>
                                  </div>
                                  <div className="swiper-slide">
                                    <div className="slide__icon">
                                      <img src={uxIcon} alt="UX icon" />
                                    </div>
                                    <div className="slide__number">05</div>
                                    <div className="slide__title">
                                      UX challenge
                                    </div>

                                    <div className="slide__sub-title">
                                      Corporate branding
                                    </div>
                                    <p>
                                      By showcasing elements of the new corporate branding, all users of the site will feel an increase in trust, familiarity and consistent messaging. Consistent branding will also help unify disparate business units and solidify a unique brand identity genuine to Pindan. We will know if we are successful by evaluating customer satisfaction through qualitative data.

                                    </p>
                                  </div>

                                  <div className="swiper-slide">
                                    <div className="slide__icon">
                                      <img src={uxIcon} alt="UX icon" />
                                    </div>
                                    <div className="slide__number">06</div>
                                    <div className="slide__title">
                                      UX challenge
                                    </div>

                                    <div className="slide__sub-title">
                                      Engaging content
                                    </div>
                                    <p>
                                      By designing content to be more engaging, clean and structured for all users we will entice greater read time and promote conversions. By taking a pragmatic approach to grouping related content and applying modern design elements such as beautiful typography, imagery, and white space we will create a fluid web experience that aligns with Pindan’s brand and strategic objectives. The use of heat maps can also show what content is most engaging.


                                    </p>
                                  </div>

                                  <div className="swiper-slide">
                                    <div className="slide__icon">
                                      <img src={uxIcon} alt="UX icon" />
                                    </div>
                                    <div className="slide__number">07</div>
                                    <div className="slide__title">
                                      UX challenge
                                    </div>

                                    <div className="slide__sub-title">
                                      Home page teasers
                                    </div>
                                    <p>
                                      By putting engaging content teasers from key areas of the website on the home page, it will help all users to be introduced to Pindan’s core services, which can be accessed through a single click. We will know this is a successful through, increased engagement, scrolled to position, adoption and retention. The use of heat maps can also show what content most engages users.
                                    </p>
                                  </div>
                                </Swiper>
                                <div className="swiper-pagination swiper-pagination2"></div>
                              </div>
                            </div>
                          </FadeIn>
                        </FadeUpBox>
                      </Col>
                    </Row>
                  </Container>
                </section>
              )}
            </Scene>

            <Scene
              triggerElement="#discovery"
              reverse={false}
              duration={2000}
              offset={200}
            >
              {(progress, event) => (
                <section id="discovery">
                  <Container>
                    <Row>
                      <Col lg={5} sm={12} className="cms-logos">
                        <FadeUpBox pose={progress > 0 ? "enter" : "exit"}>
                          <FadeIn>
                            <img src={kenticoLogo} alt="Kentico Logo" />

                            <img src={wordpressLogo} alt="Wordpress Logo" />
                          </FadeIn>
                        </FadeUpBox>
                      </Col>

                      <Col lg={7} sm={12} className="desc">
                        <div>
                          <FadeUpBox pose={progress > 0 ? "enter" : "exit"}>
                            <FadeIn>
                              <h2>Discovery</h2>
                              <p>
                                Pindan approached Diviv Group with the specific request to move away from their current Kentico CMS product in favour of an open-source platform with greater freedom and flexibility for content editors and administrators. It was paramount that Pindan could continue to deliver their web-based content that existed in Kentico as well as bringing in campaign sites that were part of Pindan’s services under one centralised management platform.
                              </p>
                              <p>
                                When considering open-source CMS products there is a large range to consider, all with their pros and cons. Pindan had expressed a keen interest in using WordPress based on its friendly interface and experiences staff had working with the platform elsewhere. Diviv Group evaluated the suitability against those features that currently exist on the site and found that all existing features could be implemented within a WordPress multisite platform. As a result, Diviv Group was extremely confident that Pindan would be served well by WordPress now and into the future.</p>
                            </FadeIn>
                          </FadeUpBox>
                        </div>
                      </Col>
                    </Row>
                  </Container>
                </section>
              )}
            </Scene>
          </Controller>

          <Controller>
            <Scene
              triggerElement="carousel02"
              reverse={false}
              duration={2000}
              offset={200}
            >
              {(progress, event) => (
                <section id="carousel02">
                  <Container>
                    <Row id="technicalProcess" className="carousel-section technical-slider-active">
                      <Col lg={6} sm={12} className="col-left">
                        <FadeUpBox pose={progress > 0 ? "enter" : "exit"}>
                          <FadeIn>
                            <h2>Technical scope &amp; UX Process</h2>
                            <p>
                              While evaluating WordPress’s capabilities against Pindan’s web portfolio, we highlighted some of the key points that would aid the migration process and be advantageous:
                            </p>
                            <div className="button-wrapper">


                              <button className="btn technical" onClick={technicalScope}>Technical Scope</button>
                              <button className="btn ux" onClick={uxProcess}>UX Process</button>
                            </div>
                          </FadeIn>
                        </FadeUpBox>
                      </Col>

                      <Col lg={6} sm={12} className="col-right">
                        <FadeUpBox pose={progress > 0 ? "enter" : "exit"}>
                          <FadeIn>
                            <div className="carousel-slider-container">
                              <div className="carousel__outer swiper3">
                                <Swiper {...params3}>
                                  <div className="swiper-slide">
                                    <div className="slide__icon">
                                      <img
                                        src={technicalIcon}
                                        alt="Technical icon"
                                      />
                                    </div>
                                    <div className="slide__number">01</div>
                                    <div className="slide__title">
                                      Technical scope
                                    </div>

                                    <div className="slide__sub-title">
                                      Multisite configuration
                                    </div>

                                    <p>
                                      WordPress has a Multisite feature that has been available since version 3 of the platform. This means that many sites can co-exist within the same WordPress installation and be managed through the same administration dashboard.
                                    </p>
                                  </div>

                                  <div className="swiper-slide">
                                    <div className="slide__icon">
                                      <img
                                        src={technicalIcon}
                                        alt="Technical icon"
                                      />
                                    </div>
                                    <div className="slide__number">02</div>
                                    <div className="slide__title">
                                      Technical scope
                                    </div>

                                    <div className="slide__sub-title">
                                      Seamless synchronisation
                                    </div>
                                    <p>
                                      There is seamless synchronisation, integration and compatibility with popular tools such as Google Analytics.
                                    </p>



                                  </div>


                                  <div className="swiper-slide">
                                    <div className="slide__icon">
                                      <img
                                        src={technicalIcon}
                                        alt="Technical icon"
                                      />
                                    </div>
                                    <div className="slide__number">03</div>
                                    <div className="slide__title">
                                      Technical scope
                                    </div>

                                    <div className="slide__sub-title">
                                      Intuitive interfaces
                                    </div>
                                    <p>
                                      WordPress has one of the simplest and intuitive interfaces for content editing on the market today. They are the leaders in this space allowing both technical and non-technical users alike to easily administer the site.</p>



                                  </div>






                                  <div className="swiper-slide">
                                    <div className="slide__icon">
                                      <img
                                        src={technicalIcon}
                                        alt="Technical icon"
                                      />
                                    </div>
                                    <div className="slide__number">04</div>
                                    <div className="slide__title">
                                      Technical scope
                                    </div>

                                    <div className="slide__sub-title">
                                      SEO capabilities
                                    </div>
                                    <p>
                                      WordPress features strong SEO capabilities; some say it’s even SEO friendly as the code it generates follows the accepted SEO best practices. Plus, there are many easily found and powerful SEO plugin that will further enhance an organisations content’s findability.</p>



                                  </div>





                                  <div className="swiper-slide">
                                    <div className="slide__icon">
                                      <img
                                        src={technicalIcon}
                                        alt="Technical icon"
                                      />
                                    </div>
                                    <div className="slide__number">05</div>
                                    <div className="slide__title">
                                      Technical scope
                                    </div>

                                    <div className="slide__sub-title">
                                      Massive community
                                    </div>
                                    <p>
                                      WordPress has a massive community of developers and as a by-product, there are thousands of plugins available to further extend the capabilities of the CMS product.</p>



                                  </div>



                                </Swiper>
                                <div className="swiper-pagination swiper-pagination3"></div>
                              </div>
                            </div>

                            <div className="carousel__outer swiper4">
                              <Swiper {...params4}>
                                <div className="swiper-slide">
                                  <div className="slide__icon">
                                    <img src={uxIcon} alt="UX icon" />
                                  </div>
                                  <div className="slide__number">01</div>
                                  <div className="slide__title">
                                    UX Process
                                  </div>

                                  <div className="slide__sub-title">
                                    Data collection
                                  </div>

                                  <p>
                                    Our process begins by determining what it is we’re shooting for and evaluating where your digital product is currently at. We begin by conducting stakeholder interviews and asking a series of questions to learn more about your business. We then hope to leverage off existing research using website analytics and feedback from various working groups.</p>


                                </div>

                                <div className="swiper-slide">
                                  <div className="slide__icon">
                                    <img src={uxIcon} alt="UX icon" />
                                  </div>
                                  <div className="slide__number">02</div>
                                  <div className="slide__title">
                                    UX Process
                                  </div>

                                  <div className="slide__sub-title">Empathise</div>
                                  <p>
                                    After studying the data metrics we can begin to validate claims made from stakeholders to gain an empathetic understanding how the site is used. We can then determine what problems to solve that will have the greatest impact.</p>

                                  <p>No facilitated workshops were used for this solution.</p>

                                </div>

                                <div className="swiper-slide">
                                  <div className="slide__icon">
                                    <img src={uxIcon} alt="UX icon" />
                                  </div>
                                  <div className="slide__number">03</div>
                                  <div className="slide__title">
                                    UX Process
                                  </div>

                                  <div className="slide__sub-title">
                                    Define problem
                                  </div>
                                  <p>
                                    We the define each problem as a problem statement with a human-centric focus.</p>

                                  <p>We believe by <strong>[building this feature]</strong>
                                    <strong>[for these personas]</strong> will achieve <strong>[this benefit]</strong>.
                                    We will know we are successful when this <strong>[business outcome]</strong> has been achieved.
                                  </p>
                                </div>
                                <div className="swiper-slide">
                                  <div className="slide__icon">
                                    <img src={uxIcon} alt="UX icon" />
                                  </div>
                                  <div className="slide__number">04</div>
                                  <div className="slide__title">
                                    UX Process
                                  </div>

                                  <div className="slide__sub-title">
                                    Ideation
                                  </div>
                                  <p>
                                    By now we are starting to understand users and their needs. The Diviv team will come together to think outside the box and create solutions, keeping both technical limitations and design in mind. We also invite key stakeholders to contribute to the solution by inviting them to an online <a href="https://miro.com/" target="_blank">Miro workshop</a> using screenshots and ideas from other websites to help build wireframes.
                                  </p>
                                </div>
                                <div className="swiper-slide">
                                  <div className="slide__icon">
                                    <img src={uxIcon} alt="UX icon" />
                                  </div>
                                  <div className="slide__number">05</div>
                                  <div className="slide__title">
                                    UX Process
                                  </div>

                                  <div className="slide__sub-title">
                                    Prototype
                                  </div>
                                  <p>
                                    We will then create static designs mockups followed by interactive prototypes which provide a fast, cost effective method to showcase UX, UI improvements that will look and behave like the finished product. </p>


                                </div>

                                <div className="swiper-slide">
                                  <div className="slide__icon">
                                    <img src={uxIcon} alt="UX icon" />
                                  </div>
                                  <div className="slide__number">06</div>
                                  <div className="slide__title">
                                    UX Process
                                  </div>

                                  <div className="slide__sub-title">
                                    Test
                                  </div>
                                  <p>
                                    Designs are then tested with real users to simulate each experience to know if each problem has been solved. We then hand over to the development team to begin the coding process. A short interactive video  presentation to showcase UX and UI improvements is then created. This makes obtaining sign off from Exec much easier being a visual experience knowing what the final solution will be.
                                  </p>
                                </div>


                              </Swiper>
                              <div className="swiper-pagination swiper-pagination4"></div>
                            </div>
                          </FadeIn>
                        </FadeUpBox>
                      </Col>
                    </Row>
                  </Container>
                </section>
              )}
            </Scene>
          </Controller>

          <Controller>
            <Scene
              triggerElement="#solution"
              reverse={false}
              duration={2000}
              offset={200}
            >
              {(progress, event) => (
                <section id="solution">
                  <Container>
                    <Row>
                      <Col lg={4} sm={12} className="title">
                        <FadeUpBox pose={progress > 0 ? "enter" : "exit"}>
                          <FadeIn>
                            <h2>How we solve your technical problem</h2>
                          </FadeIn>
                        </FadeUpBox>
                      </Col>

                      <Col lg={8} sm={12} className="desc">

                        <FadeUpBox pose={progress > 0 ? "enter" : "exit"}>
                          <FadeIn>
                            <p>
                              Diviv Group proposed a comprehensive solution to meet all of Pindan’s challenges and requirements using the WordPress CMS. All pages will use as much of the default functionality already delivered by WordPress. This is further enhanced with Diviv’s  custom developed widgets and complemented by using some tried, tested and trusted  plugin from WordPress’s vast plugin library.
                            </p>
                            <p>The high level implementation can be summarised in the following steps:</p>

                            <ul className="solution-list">
                              <li>
                                Existing web site review from a content and
                                feature perspective, both UX/UI and technical</li>
                              <li>
                                UX/UI Evaluation to go through findings and
                                confirm requirements</li>
                              <li>
                                Technical workshop to ensure that the right solution is being implemented and understood by all team members</li>
                              <li>
                                Gather existing brand artefacts (stylesheets and imagery that are not content driven)</li>
                              <li>
                                WordPress installation and configuration
                              </li>
                              <li>Custom widget development</li>
                              <li>Plugin selection and/or development</li>
                              <li>Content migration and entry</li>
                              <li>Production deployment</li>
                              <li>Training and support</li>
                            </ul>
                          </FadeIn>
                        </FadeUpBox>

                      </Col>
                    </Row>
                  </Container>

                  <Container>
                    <Row>
                      <Col lg={4} sm={12} className="sol-col">
                        <div className="tile">
                          <div className="tile__icon">
                            <img
                              className="offset"
                              src={solution01Icon}
                              alt="Option 01"
                            />
                          </div>

                          <div className="tile__label">Option 1</div>

                          <div className="tile__title">
                            WordPress MultiSite
                          </div>
                          <div className="tile__desc">
                            <p>
                              The method by which you combine multiple sites
                              into one single WordPress database but manage
                              them individually as if they were one site.
                            </p>
                          </div>
                        </div>
                      </Col>
                      <Col lg={4} sm={12} className="sol-col">
                        <div className="tile">
                          <div className="tile__icon">
                            <img src={solution02Icon} alt="Option 02" />
                          </div>

                          <div className="tile__label">Option 2</div>

                          <div className="tile__title">
                            Umbrella Management
                          </div>
                          <div className="tile__desc">
                            <p>
                              Administrator manages multiple separate
                              WordPress sites each having their own separate
                              database.
                            </p>
                          </div>
                        </div>
                      </Col>
                      <Col lg={4} sm={12} className="sol-col">
                        <div className="tile">
                          <div className="tile__icon">
                            <img
                              className="offset"
                              src={solution03Icon}
                              alt="Option 03"
                            />
                          </div>

                          <div className="tile__label">Option 3</div>

                          <div className="tile__title">
                            WordPress Subsites
                          </div>
                          <div className="tile__desc">
                            <p>
                              The method by which you combine multiple sites
                              into separate folders within the main site sharing a single content database.
                            </p>
                          </div>
                        </div>
                      </Col>
                    </Row>
                  </Container>
                </section>
              )}
            </Scene>
          </Controller>

          <Controller>
            <Scene
              triggerElement="#solution-video"
              reverse={false}
              duration={2000}
              offset={200}
            >
              {(progress, event) => (
                <section id="solution-video">
                  <Container>
                    <Row>
                      <Col lg={12} sm={12}>
                        <FadeUpBox pose={progress > 0 ? "enter" : "exit"}>
                          <FadeIn>
                            <h2>UX SOLUTION</h2>




                            <div id="video-tabs" className="tabbed-navigation-container tab-one-active">
                              <div className="tab tab-one">

                                <video playsInline controls muted width="1078" height="810" poster={videoPosterPreviousHomepage}>
                                  <source src={homePage} type="video/mp4" />
                                </video>


                              </div>
                              <div className="tab tab-two">

                                <video playsInline controls muted width="1078" height="810" poster={videoPosterNavigation} >
                                  <source src={navigation} type="video/mp4" />
                                </video>


                              </div>
                              <div className="tab tab-three">

                                <video playsInline controls muted width="1078" height="810" poster={videoPosterSearch} >
                                  <source src={search} type="video/mp4" />
                                </video>




                              </div>
                              <div className="tab tab-four">


                                <video playsInline controls muted width="1078" height="810" poster={videoPosterDevelopments}>
                                  <source src={development} type="video/mp4" />
                                </video>




                              </div>

                              <div className="tab tab-five">


                                <video playsInline controls muted width="1078" height="810" poster={videoPosterLogin}>
                                  <source src={login} type="video/mp4" />
                                </video>




                              </div>







                              <div className="tabbed-navigation">
                                <div className="mobile-scroll-container">
                                  <div id="video01" className="btn-tab" onClick={videoOne} >Homepage</div>
                                  <div id="video02" className="btn-tab" onClick={videoTwo}>Navigation</div>
                                  <div id="video03" className="btn-tab" onClick={videoThree}>Search</div>
                                  <div id="video04" className="btn-tab" onClick={videoFour}>Developments</div>
                                  <div id="video05" className="btn-tab" onClick={videoFive}>Login</div>

                                </div>
                              </div>
                            </div>

                          </FadeIn>
                        </FadeUpBox>
                      </Col>
                    </Row>
                  </Container>

                  <Container>
                    <Row>
                      <Col lg={4} sm={12}>
                        <p className="emphasis">Knowing what we are shooting for, by having a metric for success, is an important aspect of any project. To set the compass and head off in a direction determines our strategy, timeline and process. </p>


                      </Col>
                      <Col lg={8} sm={12} className="ux-desc">

                        <p>To know we’ve had a positive impact on our client’s business objectives through measured performance indicators is extremely important to us. However, once a project is launched, we don’t just simply wipe our hands and send you on your way. The game has just started. With technology changing at a rapid pace, we believe in being agile, continuously improving and refining a digital solution to ensure the likelihood of business objectives being met.</p>

                        <p>To help us know if we are doing a good job, we continue to gather qualitative and quantitative data to identify further enhancements to the frontend and backend of the digital solution. We can then make the necessary tweaks to ensure business goals are being achieved. </p>



                      </Col>
                    </Row>
                  </Container>
                </section>
              )}
            </Scene>
          </Controller>




          <Controller>
            <Scene
              triggerElement="#accountability"
              reverse={false}
              duration={2000}
              offset={200}
            >
              {(progress, event) => (
                <section id="accountability">
                  <Container>
                    <Row>
                      <Col lg={12} sm={12}>
                        <FadeUpBox pose={progress > 0 ? "enter" : "exit"}>
                          <FadeIn>
                            <div className="desc">
                              <h2>Accountability</h2>
                              <p>We use the following metrics to know how we’re tracking, measure success, and plan our ongoing strategy</p>
                            </div>


                            <div id="accountability-tabs" className="tabbed-navigation-container tab-one-active">


                              <div className="tabbed-navigation">
                                <div id="btn01" className="btn-tab" onClick={tabOne} >Metrics</div>
                                <div id="btn02" className="btn-tab" onClick={tabTwo}> Attitudinal</div>


                              </div>

                              <div className="tab tab-one">
                                <div className="kpi">
                                  <div className="kpi__col">

                                    <div className="kpi__wrapper">
                                      <div className="kpi__icon">
                                        <img src={firstClickIcon} alt="First click" />
                                      </div>
                                      <div className="kpi__name">
                                        First click tests
                                      </div>
                                    </div>
                                  </div>
                                  <div className="kpi__col">
                                    <div className="kpi__wrapper">
                                      <div className="kpi__icon">
                                        <img src={onTimeIcon} alt="On time" />
                                      </div>
                                      <div className="kpi__name">
                                        Deliver on Time
                                      </div>
                                    </div>
                                  </div>
                                  <div className="kpi__col">

                                    <div className="kpi__wrapper">
                                      <div className="kpi__icon">
                                        <img src={timeOnTaskIcon} alt="Time on task" />

                                      </div>
                                      <div className="kpi__name">
                                        Time on task
                                      </div>
                                    </div>
                                  </div>
                                  <div className="kpi__col">

                                    <div className="kpi__wrapper">
                                      <div className="kpi__icon">
                                        <img src={retentionIcon} alt="Retention" />
                                      </div>
                                      <div className="kpi__name">
                                        Rentention
                                      </div>
                                    </div>
                                  </div>
                                  <div className="kpi__col">

                                    <div className="kpi__wrapper">
                                      <div className="kpi__icon">
                                        <img src={engagementIcon} alt="Engagement" />
                                      </div>
                                      <div className="kpi__name">
                                        Engagement
                                      </div>
                                    </div>
                                  </div>
                                  <div className="kpi__col">

                                    <div className="kpi__wrapper">
                                      <div className="kpi__icon">
                                        <img src={heatMapIcon} alt="heat maps" />
                                      </div>
                                      <div className="kpi__name">
                                        Heat maps
                                      </div>
                                    </div>
                                  </div>
                                  <div className="kpi__col">

                                    <div className="kpi__wrapper">
                                      <div className="kpi__icon">

                                        <img src={adoptionIcon} alt="adoption" />

                                      </div>
                                      <div className="kpi__name">
                                        Adoption
                                      </div>
                                    </div>
                                  </div>
                                  <div className="kpi__col">

                                    <div className="kpi__wrapper">
                                      <div className="kpi__icon">
                                        <img src={clickEventIcon} alt="click event" />
                                      </div>
                                      <div className="kpi__name">
                                        Click events
                                      </div>
                                    </div>
                                  </div>
                                  <div className="kpi__col">

                                    <div className="kpi__wrapper">
                                      <div className="kpi__icon">

                                        <img src={successIcon} alt="Task success" />

                                      </div>
                                      <div className="kpi__name">
                                        Task success
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>

                              <div className="tab tab-two">

                                <div className="kpi">
                                  <div className="kpi__col">

                                    <div className="kpi__wrapper">
                                      <div className="kpi__icon">
                                        NPM
                                      </div>
                                      <div className="kpi__name">
                                        Net Promoter Score
                                      </div>
                                    </div>
                                  </div>


                                  <div className="kpi__col">

                                    <div className="kpi__wrapper">
                                      <div className="kpi__icon">
                                        SUSS
                                      </div>
                                      <div className="kpi__name">
                                        System Usability Scale
                                      </div>
                                    </div>
                                  </div>



                                  <div className="kpi__col">

                                    <div className="kpi__wrapper">
                                      <div className="kpi__icon">
                                        CSAT
                                      </div>
                                      <div className="kpi__name">
                                        Customer Satisfaction
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>



                            </div>


                          </FadeIn>
                        </FadeUpBox>
                      </Col>
                    </Row>
                  </Container>
                </section>
              )}
            </Scene>
          </Controller>


        </div>

        {/*  <Scene
                    triggerElement="#bottom-section"
                    reverse={false}
                    duration={2000}
                    offset={200}
                >
                    {(progress, event) => (
                        <section className="quote-wrapper">
                            <Container>
                                <QuoteBlock
                                    author="Michael Johnson"
                                    authorPosition="Senior Business Analyst"
                                    quoteText="Diviv is a solution based partner that delivers
                              front and back end website solutions with
                              innovation, professionalism and integrity. They
                              are a great team and a pleasure to work with."
                                    clientLogoUrl={eaImages.eaLogoSm.childImageSharp.fluid.src}
                                />
                            </Container>
                        </section>
                    )}
                </Scene> */}

        <CaseStudyNav
          nextUrl="/our-work/stjohn-ambulance/beats/"
          nextImage={eaImages.nextWorkImage.childImageSharp.fluid.src}
          nextTitle="BEATS St John Ambulance"
        />
      </LayoutCaseStudy>
    </>
  );
}

export default PindanPage;
